function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

import jQuery from 'jquery';
import 'popper.js';
import 'bootstrap';
import Swiper from 'swiper';
jQuery(document).ready(function ($) {
  var mySwiper = new Swiper('.swiper-container', {
    loop: true,
    autoplay: {
      delay: 4000
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    }
  }); // $("html").hasClass("lt-ie9") || $("#toggle").sidr({
  //     name: "sidr-main",
  //     side: "right",
  //     source: "nav"
  // });

  $("#mansched").glDatePicker({
    calendarOffset: {
      x: 0,
      y: 1
    },
    showAlways: !0,
    cssName: "mandp",
    dowNames: ["So", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
    prevArrow: " ",
    nextArrow: " ",
    dowOffset: 1
  });
  $('.nav-item.dropdown > a').click(function () {
    var $submenu = $(this).parent().find('.sub-menu');
    console.log($submenu);
    $('.sub-menu').removeClass('show');
    $submenu.toggleClass('show');
    return false;
  });
  $("#karussell").flexisel({
    visibleItems: 4,
    enableResponsiveBreakpoints: !0,
    responsiveBreakpoints: {
      tablet: {
        changePoint: 940,
        visibleItems: 2
      },
      phone: {
        changePoint: 640,
        visibleItems: 1
      }
    }
  });
}), function (e, t, a) {
  e.fn.doubleTapToGo = function () {
    return "ontouchstart" in t || navigator.msMaxTouchPoints || navigator.userAgent.toLowerCase().match(/windows phone os 7/i) ? (this.each(function () {
      var t = !1;
      e(this).on("click", function (a) {
        var n = e(this);
        n[0] != t[0] && (a.preventDefault(), t = n);
      }), e(a).on("click touchstart MSPointerDown", function (a) {
        for (var n = !0, i = e(a.target).parents(), r = 0; r < i.length; r++) {
          i[r] == t[0] && (n = !1);
        }

        n && (t = !1);
      });
    }), this) : !1;
  };
}(jQuery, window, document), function (e) {
  "use strict";

  function t(e, a) {
    if (this.constructor !== t) {
      var n = new t(e, a);
      return n.open(), n;
    }

    this.id = t.id++, this.setup(e, a);
  }

  if ("undefined" == typeof e) return "console" in window && window.console.info("Too much lightness, Featherlight needs jQuery."), void 0;

  var a = function a(e) {
    if (27 === e.keyCode && !e.isDefaultPrevented()) {
      var a = t.current();
      a && a.closeOnEsc && (a.$instance.find("." + a.namespace + "-close:first").click(), e.preventDefault());
    }
  };

  t.prototype = {
    constructor: t,
    namespace: "featherlight",
    targetAttr: "data-featherlight",
    variant: null,
    resetCss: !1,
    background: null,
    openTrigger: "click",
    closeTrigger: "click",
    filter: null,
    root: "body",
    openSpeed: 250,
    closeSpeed: 250,
    closeOnClick: "background",
    closeOnEsc: !0,
    closeIcon: "&#10005;",
    otherClose: null,
    beforeOpen: e.noop,
    beforeClose: e.noop,
    afterOpen: e.noop,
    afterClose: e.noop,
    type: null,
    contentFilters: ["jquery", "image", "html", "ajax", "text"],
    setup: function setup(t, n) {
      "object" != _typeof(t) || t instanceof e != !1 || n || (n = t, t = void 0);
      var i = e.extend(this, n, {
        target: t
      }),
          r = i.resetCss ? i.namespace + "-reset" : i.namespace,
          l = e(i.background || '<div class="' + r + '"><div class="' + r + '-content"><span class="' + r + "-close-icon " + i.namespace + '-close">' + i.closeIcon + "</span></div></div>"),
          s = "." + i.namespace + "-close" + (i.otherClose ? "," + i.otherClose : "");
      return i.$instance = l.clone().addClass(i.variant), i.closeOnEsc && a && (e(document).bind("keyup." + this.constructor.prototype.namespace, a), a = null), i.$instance.on(i.closeTrigger + "." + i.namespace, function (t) {
        var a = e(t.target);
        ("background" === i.closeOnClick && a.is("." + i.namespace) || "anywhere" === i.closeOnClick || a.is(s)) && (t.preventDefault(), i.close());
      }), this;
    },
    getContent: function getContent() {
      var t = this,
          a = this.constructor.contentFilters,
          n = function n(e) {
        return t.$currentTarget && t.$currentTarget.attr(e);
      },
          i = n(t.targetAttr),
          r = t.target || i || "",
          l = a[t.type];

      if (!l && r in a && (l = a[r], r = t.target && i), r = r || n("href") || "", !l) for (var s in a) {
        t[s] && (l = a[s], r = t[s]);
      }

      if (!l) {
        var o = r;
        if (r = null, e.each(t.contentFilters, function () {
          return l = a[this], l.test && (r = l.test(o)), !r && l.regex && o.match && o.match(l.regex) && (r = o), !r;
        }), !r) return "console" in window && window.console.error("Featherlight: no content filter found " + (o ? ' for "' + o + '"' : " (no target specified)")), !1;
      }

      return l.process.call(t, r);
    },
    setContent: function setContent(t) {
      var a = this;
      return (t.is("iframe") || e("iframe", t).length > 0) && a.$instance.addClass(a.namespace + "-iframe"), a.$content = t.addClass(a.namespace + "-inner"), a.$instance.find("." + a.namespace + "-inner").remove(), a.$instance.find("." + a.namespace + "-content").append(a.$content), a;
    },
    open: function open(t) {
      var a = this;

      if (!(t && t.isDefaultPrevented() || a.beforeOpen(t) === !1)) {
        t && t.preventDefault();
        var n = a.getContent();
        if (n) return a.constructor._opened.add(a._openedCallback = function (e) {
          a.$instance.closest("body").length > 0 && (e.currentFeatherlight = a);
        }), e.when(n).done(function (e) {
          a.setContent(e).$instance.appendTo(a.root).fadeIn(a.openSpeed), a.afterOpen(t);
        }), a;
      }

      return !1;
    },
    close: function close(e) {
      var t = this;
      return t.beforeClose(e) === !1 ? !1 : (t.constructor._opened.remove(t._openedCallback), t.$instance.fadeOut(t.closeSpeed, function () {
        t.$instance.detach(), t.afterClose(e);
      }), void 0);
    }
  }, e.extend(t, {
    id: 0,
    autoBind: "[data-featherlight]",
    defaults: t.prototype,
    contentFilters: {
      jquery: {
        regex: /^[#.]\w/,
        test: function test(t) {
          return t instanceof e && t;
        },
        process: function process(t) {
          return e(t).clone(!0);
        }
      },
      image: {
        regex: /\.(png|jpg|jpeg|gif|tiff|bmp)(\?\S*)?$/i,
        process: function process(t) {
          var a = this,
              n = e.Deferred(),
              i = new Image();
          return i.onload = function () {
            n.resolve(e('<img src="' + t + '" alt="" class="' + a.namespace + '-image" />'));
          }, i.onerror = function () {
            n.reject();
          }, i.src = t, n.promise();
        }
      },
      html: {
        regex: /^\s*<[\w!][^<]*>/,
        process: function process(t) {
          return e(t);
        }
      },
      ajax: {
        regex: /./,
        process: function process(t) {
          var a = e.Deferred(),
              n = e("<div></div>").load(t, function (e, t) {
            "error" !== t && a.resolve(n.contents()), a.fail();
          });
          return a.promise();
        }
      },
      text: {
        process: function process(t) {
          return e("<div>", {
            text: t
          });
        }
      }
    },
    functionAttributes: ["beforeOpen", "afterOpen", "beforeClose", "afterClose"],
    readElementConfig: function readElementConfig(t) {
      var a = this,
          n = {};
      return t && t.attributes && e.each(t.attributes, function () {
        var t = this.name.match(/^data-featherlight-(.*)/);

        if (t) {
          var i = this.value,
              r = e.camelCase(t[1]);
          if (e.inArray(r, a.functionAttributes) >= 0) i = new Function(i);else try {
            i = e.parseJSON(i);
          } catch (l) {}
          n[r] = i;
        }
      }), n;
    },
    attach: function attach(t, a, n) {
      var i = this;
      "object" != _typeof(a) || a instanceof e != !1 || n || (n = a, a = void 0), n = e.extend({}, n);
      var r = e.extend({}, i.defaults, i.readElementConfig(t[0]), n);
      t.on(r.openTrigger + "." + r.namespace, r.filter, function (r) {
        var l = e.extend({
          $currentTarget: e(this)
        }, i.readElementConfig(t[0]), i.readElementConfig(this), n);
        new e.featherlight(a, l).open(r);
      });
    },
    current: function current() {
      var e = {};
      return this._opened.fire(e), e.currentFeatherlight;
    },
    close: function close() {
      var e = this.current();
      e && e.close();
    },
    _opened: e.Callbacks()
  }), e.featherlight = t, e.fn.featherlight = function (e, a) {
    return t.attach(this, e, a), this;
  }, e(document).ready(function () {
    t.autoBind && (e(document).featherlight({
      filter: t.autoBind
    }), e(t.autoBind).filter("[data-featherlight-filter]").each(function () {
      e(this).featherlight();
    }));
  });
}(jQuery), function (e) {
  "use strict";

  var t = window.console || function () {},
      a = {
    defaults: {
      debug: !1,
      RODom: !1,
      patternLibrary: {
        phone: /([\+][0-9]{1,3}([ \.\-])?)?([\(]{1}[0-9]{1,6}[\)])?([0-9A-Za-z \.\-]{1,32})(([A-Za-z \:]{1,11})?[0-9]{1,4}?)/,
        email: /((([a-zA-Z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-zA-Z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?/,
        url: /(https?|ftp):\/\/(((([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?/,
        number: /-?(?:\d+|\d{1,3}(?:,\d{3})+)?(?:\.\d+)?/,
        dateISO: /\d{4}[\/\-]\d{1,2}[\/\-]\d{1,2}/,
        alpha: /[a-zA-Z]+/,
        alphaNumeric: /\w+/,
        integer: /-?\d+/
      },
      classPrefix: "h5-",
      errorClass: "ui-state-error",
      validClass: "ui-state-valid",
      activeClass: "active",
      requiredClass: "required",
      requiredAttribute: "required",
      patternAttribute: "pattern",
      errorAttribute: "data-h5-errorid",
      customEvents: {
        validate: !0
      },
      kbSelectors: ":input:not(:button):not(:disabled):not(.novalidate)",
      focusout: !0,
      focusin: !1,
      change: !0,
      keyup: !1,
      activeKeyup: !0,
      mSelectors: '[type="range"]:not(:disabled):not(.novalidate), :radio:not(:disabled):not(.novalidate), :checkbox:not(:disabled):not(.novalidate), select:not(:disabled):not(.novalidate), option:not(:disabled):not(.novalidate)',
      click: !0,
      requiredVar: "h5-required",
      patternVar: "h5-pattern",
      stripMarkup: !0,
      submit: !0,
      focusFirstInvalidElementOnSubmit: !0,
      validateOnSubmit: !0,
      invalidCallback: function invalidCallback() {},
      validCallback: function validCallback() {},
      allValidSelectors: ":input:visible:not(:button):not(:disabled):not(.novalidate)",
      markInvalid: function markInvalid(t) {
        var a = e(t.element),
            n = e(t.errorID);
        return a.addClass(t.errorClass).removeClass(t.validClass), a.addClass(t.settings.activeClass), n.length && (a.attr("title") && n.text(a.attr("title")), n.show()), a.data("valid", !1), t.settings.invalidCallback.call(t.element, t.validity), a;
      },
      markValid: function markValid(t) {
        var a = e(t.element),
            n = e(t.errorID);
        return a.addClass(t.validClass).removeClass(t.errorClass), n.length && n.hide(), a.data("valid", !0), t.settings.validCallback.call(t.element, t.validity), a;
      },
      unmark: function unmark(t) {
        var a = e(t.element);
        return a.removeClass(t.errorClass).removeClass(t.validClass), a.form.find("#" + t.element.id).removeClass(t.errorClass).removeClass(t.validClass), a;
      }
    }
  },
      n = a.defaults,
      i = n.patternLibrary,
      r = function r(t) {
    return e.extend({
      customError: t.customError || !1,
      patternMismatch: t.patternMismatch || !1,
      rangeOverflow: t.rangeOverflow || !1,
      rangeUnderflow: t.rangeUnderflow || !1,
      stepMismatch: t.stepMismatch || !1,
      tooLong: t.tooLong || !1,
      typeMismatch: t.typeMismatch || !1,
      valid: t.valid || !0,
      valueMissing: t.valueMissing || !1
    }, t);
  },
      l = {
    isValid: function isValid(t, a) {
      var n = e(this);
      return a = t && a || {}, a.revalidate !== !1 && n.trigger("validate"), n.data("valid");
    },
    allValid: function allValid(t, a) {
      var n,
          i,
          r = !0,
          l = [],
          s = e(this),
          o = [],
          c = function c(e, t) {
        t.e = e, l.push(t);
      },
          d = e.extend({}, t, a);

      return a = a || {}, s.trigger("formValidate", {
        settings: e.extend(!0, {}, d)
      }), s.undelegate(d.allValidSelectors, ".allValid", c), s.delegate(d.allValidSelectors, "validated.allValid", c), n = s.find(d.allValidSelectors), i = n.filter(function () {
        var e;

        if ("INPUT" === this.tagName && "radio" === this.type) {
          if (e = this.name, o[e] === !0) return !1;
          o[e] = !0;
        }

        return !0;
      }), i.each(function () {
        var t = e(this);
        r = t.h5Validate("isValid", a) && r;
      }), s.trigger("formValidated", {
        valid: r,
        elements: l
      }), r;
    },
    validate: function validate(a) {
      var n,
          i = e(this),
          l = i.filter("[pattern]")[0] ? i.attr("pattern") : !1,
          s = new RegExp("^(?:" + l + ")$"),
          o = null,
          c = i.is("[type=checkbox]") ? i.is(":checked") : i.is("[type=radio]") ? (o = i.parents("form").find('input[name="' + i.attr("name") + '"]')).filter(":checked").length > 0 : i.val(),
          d = a.errorClass,
          u = a.validClass,
          f = i.attr(a.errorAttribute) || !1,
          h = f ? "#" + f.replace(/(:|\.|\[|\])/g, "\\$1") : !1,
          p = !1,
          v = r({
        element: this,
        valid: !0
      }),
          g = e("<input required>");
      p = g.filter("[required]") && g.filter("[required]").length ? i.filter("[required]").length && "false" !== i.attr("required") : void 0 !== i.attr("required"), a.debug && window.console && (t.log('Validate called on "' + c + '" with regex "' + s + '". Required: ' + p), t.log("Regex test: " + s.test(c) + ", Pattern: " + l)), n = parseInt(i.attr("maxlength"), 10), !isNaN(n) && c.length > n && (v.valid = !1, v.tooLong = !0), p && !c ? (v.valid = !1, v.valueMissing = !0) : l && !s.test(c) && c ? (v.valid = !1, v.patternMismatch = !0) : a.RODom || a.markValid({
        element: this,
        validity: v,
        errorClass: d,
        validClass: u,
        errorID: h,
        settings: a
      }), v.valid || a.RODom || a.markInvalid({
        element: this,
        validity: v,
        errorClass: d,
        validClass: u,
        errorID: h,
        settings: a
      }), i.trigger("validated", v), null !== o && a.alreadyCheckingRelatedRadioButtons !== !0 && (a.alreadyCheckingRelatedRadioButtons = !0, o.not(i).trigger("validate"), a.alreadyCheckingRelatedRadioButtons = !1);
    },
    delegateEvents: function delegateEvents(t, a, n, i) {
      var r = {},
          l = 0,
          s = function s() {
        i.validate.call(this, i);
      };

      e.each(a, function (e, t) {
        t && (r[e] = e);
      });

      for (l in r) {
        r.hasOwnProperty(l) && e(n).delegate(t, r[l] + ".h5Validate", s);
      }

      return n;
    },
    bindDelegation: function bindDelegation(t) {
      var a,
          n = e(this);
      return e.each(i, function (a, n) {
        var i = n.toString();
        i = i.substring(1, i.length - 1), e("." + t.classPrefix + a).attr("pattern", i);
      }), a = n.filter("form").add(n.find("form")).add(n.parents("form")), a.attr("novalidate", "novalidate").submit(s), a.find("input[formnovalidate][type='submit']").click(function () {
        e(this).closest("form").unbind("submit", s);
      }), this.each(function () {
        var e = {
          focusout: t.focusout,
          focusin: t.focusin,
          change: t.change,
          keyup: t.keyup
        },
            a = {
          click: t.click
        },
            n = {
          keyup: t.activeKeyup
        };
        t.delegateEvents(":input", t.customEvents, this, t), t.delegateEvents(t.kbSelectors, e, this, t), t.delegateEvents(t.mSelectors, a, this, t), t.delegateEvents(t.activeClassSelector, n, this, t), t.delegateEvents("textarea[maxlength]", {
          keyup: !0
        }, this, t);
      });
    }
  },
      s = function s(t) {
    var a,
        n,
        i = d.call(this);

    if (i.submit === !0) {
      if (a = e(this), n = a.h5Validate("allValid", {
        revalidate: i.validateOnSubmit === !0
      }), n !== !0 && (t.preventDefault(), i.focusFirstInvalidElementOnSubmit === !0)) {
        var r = e(i.allValidSelectors, a).filter(function () {
          return e(this).h5Validate("isValid", {
            revalidate: !1
          }) !== !0;
        });
        r.first().focus();
      }

      return n;
    }
  },
      o = [],
      c = function c(t) {
    var a = e.extend({}, n, t, l),
        i = a.classPrefix + a.activeClass;
    return e.extend(a, {
      activeClass: i,
      activeClassSelector: "." + i,
      requiredClass: a.classPrefix + a.requiredClass,
      el: this
    });
  },
      d = function d() {
    var t = e(this).closest("[data-h5-instanceId]");
    return o[t.attr("data-h5-instanceId")];
  },
      u = function u(t) {
    var a = o.push(t) - 1;
    t.RODom !== !0 && e(this).attr("data-h5-instanceId", a), e(this).trigger("instance", {
      "data-h5-instanceId": a
    });
  };

  e.h5Validate = {
    addPatterns: function addPatterns(e) {
      var t,
          a = n.patternLibrary;

      for (t in e) {
        e.hasOwnProperty(t) && (a[t] = e[t]);
      }

      return a;
    },
    validValues: function validValues(t, a) {
      var n,
          i = 0,
          r = a.length,
          l = "";

      for (i = 0; r > i; i += 1) {
        l = l ? l + "|" + a[i] : a[i];
      }

      n = new RegExp("^(?:" + l + ")$"), e(t).data("regex", n);
    }
  }, e.fn.h5Validate = function (t) {
    var a, n, i;
    return "string" == typeof t && "function" == typeof l[t] ? (i = d.call(this), n = [].slice.call(arguments, 0), a = t, n.shift(), n = e.merge([i], n), i[a].apply(this, n)) : (i = c.call(this, t), u.call(this, i), l.bindDelegation.call(this, i));
  };
}(jQuery), function (e) {
  e.fn.leanSlider = function (t) {
    var a = {
      pauseTime: 4e3,
      pauseOnHover: !0,
      startSlide: 0,
      directionNav: "",
      directionNavPrevBuilder: "",
      directionNavNextBuilder: "",
      controlNav: "",
      controlNavBuilder: "",
      prevText: "Prev",
      nextText: "Next",
      beforeChange: function beforeChange() {},
      afterChange: function afterChange() {},
      afterLoad: function afterLoad() {}
    },
        n = this,
        i = {},
        r = e(this),
        l = r.children(),
        s = 0,
        o = 0,
        c = function c() {
      if (i = e.extend({}, a, t), r.addClass("lean-slider"), l.addClass("lean-slider-slide"), s = i.startSlide, (i.startSlide < 0 || i.startSlide >= l.length) && (s = 0), e(l[s]).addClass("current"), i.directionNav && e(i.directionNav).length) {
        var c = e('<a href="#" class="lean-slider-prev">' + i.prevText + "</a>"),
            f = e('<a href="#" class="lean-slider-next">' + i.nextText + "</a>");
        i.directionNavPrevBuilder && (c = e(i.directionNavPrevBuilder.call(this, i.prevText))), i.directionNavNextBuilder && (f = e(i.directionNavNextBuilder.call(this, i.nextText))), c.on("click", function (e) {
          e.preventDefault(), n.prev();
        }), f.on("click", function (e) {
          e.preventDefault(), n.next();
        }), e(i.directionNav).append(c), e(i.directionNav).append(f);
      }

      return i.controlNav && e(i.controlNav).length && l.each(function (t) {
        var a = e('<a href="#" class="lean-slider-control-nav">' + (t + 1) + "</a>");
        i.controlNavBuilder && (a = e(i.controlNavBuilder.call(this, t, e(l[t])))), a.on("click", function (e) {
          e.preventDefault(), n.show(t);
        }), e(i.controlNav).append(a);
      }), i.pauseOnHover && i.pauseTime && i.pauseTime > 0 && r.hover(function () {
        clearTimeout(o);
      }, function () {
        d();
      }), u(), d(), i.afterLoad.call(this), n;
    },
        d = function d() {
      i.pauseTime && i.pauseTime > 0 && (clearTimeout(o), o = setTimeout(function () {
        n.next();
      }, i.pauseTime));
    },
        u = function u() {
      i.controlNav && (e(".lean-slider-control-nav", i.controlNav).removeClass("active"), e(e(".lean-slider-control-nav", i.controlNav).get(s)).addClass("active"));
    };

    return n.prev = function () {
      i.beforeChange.call(this, s), s--, 0 > s && (s = l.length - 1), l.removeClass("current"), e(l[s]).addClass("current"), u(), d(), i.afterChange.call(this, s);
    }, n.next = function () {
      i.beforeChange.call(this, s), s++, s >= l.length && (s = 0), l.removeClass("current"), e(l[s]).addClass("current"), u(), d(), i.afterChange.call(this, s);
    }, n.show = function (t) {
      i.beforeChange.call(this, s), s = t, 0 > s && (s = l.length - 1), s >= l.length && (s = 0), l.removeClass("current"), e(l[s]).addClass("current"), u(), d(), i.afterChange.call(this, s);
    }, c();
  };
}(jQuery), function () {
  jQuery.fn.glDatePicker = function (e) {
    var t = "glDatePicker",
        a = this.data(t);
    return a ? e === !0 ? a : this : this.each(function () {
      return jQuery(this).data(t, new glDatePicker(this, e));
    });
  }, jQuery.fn.glDatePicker.defaults = {
    cssName: "default",
    zIndex: 1e3,
    borderSize: 1,
    calendarOffset: {
      x: 0,
      y: 1
    },
    showAlways: !1,
    hideOnClick: !0,
    allowMonthSelect: !0,
    allowYearSelect: !0,
    todayDate: new Date(),
    selectedDate: null,
    prevArrow: "◄",
    nextArrow: "►",
    selectableDates: null,
    selectableDateRange: null,
    specialDates: null,
    selectableMonths: null,
    selectableYears: null,
    selectableDOW: null,
    monthNames: null,
    dowNames: null,
    dowOffset: 0,
    onClick: function onClick(e, t, a) {
      e.val(a.toLocaleDateString());
    },
    onHover: function onHover() {},
    onShow: function onShow(e) {
      e.show();
    },
    onHide: function onHide(e) {
      e.hide();
    },
    firstDate: null
  };

  var glDatePicker = function () {
    function glDatePicker(e, t) {
      var a = this;
      a.el = jQuery(e);
      var n = a.el;
      a.options = jQuery.extend(!0, {}, jQuery.fn.glDatePicker.defaults, t);
      var i = a.options;
      a.calendar = jQuery(jQuery.find("[gldp-el=" + n.attr("gldp-id") + " ]")), i.selectedDate = i.selectedDate || i.todayDate, i.firstDate = new Date(i.firstDate || i.selectedDate)._first(), (n.attr("gldp-id") || "").length || n.attr("gldp-id", "gldp-" + Math.round(1e10 * Math.random())), n.addClass("gldp-el").bind("click", function (e) {
        a.show(e);
      }).bind("focus", function (e) {
        a.show(e);
      }), a.calendar.length && !i.showAlways && a.calendar.hide(), jQuery(document).bind("mouseup", function (e) {
        var t = e.target,
            i = a.calendar;
        n.is(t) || i.is(t) || 0 !== i.has(t).length || !i.is(":visible") || a.hide();
      }), a.render();
    }

    return glDatePicker.prototype = {
      show: function show() {
        jQuery.each(jQuery(".gldp-el").not(this.el), function (e, t) {
          t.length && t.options.onHide(t.calendar);
        }), this.options.onShow(this.calendar);
      },
      hide: function hide() {
        this.options && !this.options.showAlways && this.options.onHide(this.calendar);
      },
      render: function render(renderCalback) {
        var self = this,
            el = self.el,
            options = self.options,
            calendar = self.calendar,
            coreClass = " core border ",
            cssName = "gldp-" + options.cssName,
            todayVal = options.todayDate._val(),
            todayTime = todayVal.time,
            maxRow = 6,
            maxCol = 7,
            borderSize = options.borderSize + "px",
            getSelectableList = function getSelectableList(e, t, a) {
          for (var n = [], i = e; t >= i; i++) {
            n.push(i);
          }

          if (a) {
            var r = [];
            jQuery.each(a, function (a, n) {
              n >= e && t >= n && r._indexOf(n) < 0 && r.push(n);
            }), n = r.length ? r : n;
          }

          return n.sort(), n;
        },
            selectableMonths = getSelectableList(0, 11, options.selectableMonths),
            selectableYears = getSelectableList(todayVal.year - 5, todayVal.year + 5, options.selectableYears),
            selectableDOW = getSelectableList(0, 6, options.selectableDOW),
            dowNames = options.dowNames || ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            monthNames = options.monthNames || ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            containerWidth = el.outerWidth(),
            containerHeight = containerWidth,
            getCellSize = function getCellSize(e, t) {
          return e / t + options.borderSize / t * (t - 1);
        },
            cellWidth = getCellSize(containerWidth, maxCol),
            cellHeight = getCellSize(containerHeight, maxRow + 2);

        calendar.length ? eval(calendar.data("is")) || (containerWidth = calendar.outerWidth(), containerHeight = calendar.outerHeight(), cellWidth = getCellSize(containerWidth, maxCol), cellHeight = getCellSize(containerHeight, maxRow + 2)) : (self.calendar = calendar = jQuery("<div/>").attr("gldp-el", el.attr("gldp-id")).data("is", !0).css({
          display: options.showAlways ? void 0 : "none",
          zIndex: options.zIndex,
          width: cellWidth * maxCol + "px"
        }), jQuery("body").append(calendar)), el.is(":visible") || calendar.hide(), calendar.removeClass().addClass(cssName).children().remove();

        var onResize = function onResize() {
          var e = el.offset();
          calendar.css({
            top: e.top + el.outerHeight() + options.calendarOffset.y + "px",
            left: e.left + options.calendarOffset.x + "px"
          });
        };

        jQuery(window).resize(onResize), onResize();

        var cellCSS = {
          width: cellWidth + "px",
          height: cellHeight + "px",
          lineHeight: cellHeight + "px"
        },
            setFirstDate = function setFirstDate(e) {
          e && (options.firstDate = e, self.render());
        },
            getFirstDate = function getFirstDate(e) {
          var t = new Date(options.firstDate);

          for (e = e || 0;;) {
            if (t.setMonth(t.getMonth() + e), t.setDate(Math.min(1, t._max())), 0 == e) break;

            var a = t._val(),
                n = a.month,
                i = a.year;

            if (-1 != selectableMonths._indexOf(n)) {
              if (-1 != selectableYears._indexOf(i)) break;
              if (i < selectableYears[0] || i > selectableYears[selectableYears.length - 1]) return null;
            }
          }

          return t;
        },
            prevFirstDate = getFirstDate(-1),
            nextFirstDate = getFirstDate(1),
            firstDate = options.firstDate = getFirstDate(),
            firstDateVal = firstDate._val(),
            firstDateMonth = firstDateVal.month,
            firstDateYear = firstDateVal.year,
            startDate = new Date(firstDate),
            dowOffset = Math.abs(Math.min(6, Math.max(0, options.dowOffset))),
            startOffset = startDate.getDay() - dowOffset;

        startOffset = 1 > startOffset ? -7 - startOffset : -startOffset, dowNames = dowNames.concat(dowNames).slice(dowOffset, dowOffset + 7), startDate._add(startOffset);
        var showPrev = prevFirstDate,
            showNext = nextFirstDate,
            monyearClass = coreClass + "monyear ",
            prevCell = jQuery("<div/>").addClass(monyearClass).css(jQuery.extend({}, cellCSS, {
          borderWidth: borderSize + " 0 0 " + borderSize
        })).append(jQuery("<a/>").addClass("prev-arrow" + (showPrev ? "" : "-off")).html(options.prevArrow)).mousedown(function () {
          return !1;
        }).click(function (e) {
          "" != options.prevArrow && showPrev && (e.stopPropagation(), setFirstDate(prevFirstDate));
        }),
            titleCellCount = maxCol - 2,
            titleWidth = cellWidth * titleCellCount - titleCellCount * options.borderSize + options.borderSize,
            titleCell = jQuery("<div/>").addClass(monyearClass + "title").css(jQuery.extend({}, cellCSS, {
          width: titleWidth + "px",
          borderTopWidth: borderSize,
          marginLeft: "-" + borderSize
        })),
            nextCell = jQuery("<div/>").addClass(monyearClass).css(jQuery.extend({}, cellCSS, {
          marginLeft: "-" + borderSize,
          borderWidth: borderSize + " " + borderSize + " 0 0"
        })).append(jQuery("<a/>").addClass("next-arrow" + (showNext ? "" : "-off")).html(options.nextArrow)).mousedown(function () {
          return !1;
        }).click(function (e) {
          "" != options.nextArrow && showNext && (e.stopPropagation(), setFirstDate(nextFirstDate));
        });
        calendar.append(prevCell).append(titleCell).append(nextCell);

        for (var row = 0, cellIndex = 0; maxRow + 1 > row; row++) {
          for (var col = 0; maxCol > col; col++, cellIndex++) {
            var cellDate = new Date(startDate),
                cellClass = "day",
                cellZIndex = options.zIndex + cellIndex,
                cell = jQuery("<div/>");

            if (row) {
              cellDate._add(col + (row - 1) * maxCol);

              var cellDateVal = cellDate._val(),
                  cellDateTime = cellDateVal.time,
                  specialData = null,
                  isSelectable = !0,
                  getRepeatDate = function getRepeatDate(e, t) {
                return e.repeatYear === !0 && t.setYear(cellDateVal.year), e.repeatMonth === !0 && t.setMonth(cellDateVal.month), t._val();
              };

              cell.html(cellDateVal.date), options.selectableDateRange && (isSelectable = !1, jQuery.each(options.selectableDateRange, function (e, t) {
                var a = t.from,
                    n = t.to || null;
                return n = n || new Date(t.from.getFullYear(), t.from.getMonth(), t.from._max()), a = getRepeatDate(t, a), n = getRepeatDate(t, n), cellDateTime >= a.time && cellDateTime <= n.time ? (isSelectable = !0, !0) : void 0;
              })), options.selectableDates && ((options.selectableDateRange && !isSelectable || isSelectable && !options.selectableDateRange) && (isSelectable = !1), jQuery.each(options.selectableDates, function (e, t) {
                var a = getRepeatDate(t, t.date);
                return a.time == cellDateTime ? isSelectable = !0 : void 0;
              })), !isSelectable || selectableYears._indexOf(cellDateVal.year) < 0 || selectableMonths._indexOf(cellDateVal.month) < 0 || selectableDOW._indexOf(cellDateVal.day) < 0 ? cellClass = "noday" : (cellClass = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"][cellDateVal.day], firstDateMonth != cellDateVal.month && (cellClass += " outday"), todayTime == cellDateTime && (cellClass = "today", cellZIndex += 50), options.selectedDate._time() == cellDateTime && (cellClass = "selected", cellZIndex += 51), options.specialDates && jQuery.each(options.specialDates, function (e, t) {
                var a = getRepeatDate(t, t.date);
                a.time == cellDateTime && (cellClass = t.cssClass || "special", cellZIndex += 52, specialData = t.data);
              }), cell.mousedown(function () {
                return !1;
              }).hover(function (e) {
                e.stopPropagation();
                var t = jQuery(this).data("data");
                options.onHover(el, cell, t.date, t.data);
              }).click(function (e) {
                e.stopPropagation();
                var t = jQuery(this).data("data");
                options.selectedDate = options.firstDate = t.date, self.render(function () {
                  !options.showAlways && options.hideOnClick && self.hide();
                }), options.onClick(el, jQuery(this), t.date, t.data);
              }));
            } else cellClass = "dow", cell.html(dowNames[col]), cellDate = null;

            jQuery.extend(cellCSS, {
              borderTopWidth: borderSize,
              borderBottomWidth: borderSize,
              borderLeftWidth: row > 0 || !row && !col ? borderSize : 0,
              borderRightWidth: row > 0 || !row && 6 == col ? borderSize : 0,
              marginLeft: col > 0 ? "-" + borderSize : 0,
              marginTop: row > 0 ? "-" + borderSize : 0,
              zIndex: cellZIndex
            }), cell.data("data", {
              date: cellDate,
              data: specialData
            }).addClass(coreClass + cellClass).css(cellCSS), calendar.append(cell);
          }
        }

        var toggleYearMonthSelect = function toggleYearMonthSelect(e) {
          var t = "inline-block",
              a = "none";
          options.allowMonthSelect && (monthText.css({
            display: e ? t : a
          }), monthSelect.css({
            display: e ? a : t
          })), options.allowYearSelect && (yearText.css({
            display: e ? a : t
          }), yearSelect.css({
            display: e ? t : a
          }));
        },
            onYearMonthSelect = function onYearMonthSelect() {
          options.firstDate = new Date(yearSelect.val(), monthSelect.val(), 1), self.render();
        },
            monthSelect = jQuery("<select/>").hide().change(onYearMonthSelect),
            yearSelect = jQuery("<select/>").hide().change(onYearMonthSelect),
            monthText = jQuery("<span/>").html(monthNames[firstDateMonth]).mousedown(function () {
          return !1;
        }).click(function (e) {
          e.stopPropagation(), toggleYearMonthSelect(!1);
        }),
            yearText = jQuery("<span/>").html(firstDateYear).mousedown(function () {
          return !1;
        }).click(function (e) {
          e.stopPropagation(), toggleYearMonthSelect(!0);
        });

        jQuery.each(monthNames, function (e, t) {
          if (options.allowMonthSelect && -1 != selectableMonths._indexOf(e)) {
            var a = jQuery("<option/>").html(t).attr("value", e);
            e == firstDateMonth && a.attr("selected", "selected"), monthSelect.append(a);
          }
        }), jQuery.each(selectableYears, function (e, t) {
          if (options.allowYearSelect) {
            var a = jQuery("<option/>").html(t).attr("value", t);
            t == firstDateYear && a.attr("selected", "selected"), yearSelect.append(a);
          }
        });
        var titleYearMonth = jQuery("<div/>").append(monthText).append(monthSelect).append(yearText).append(yearSelect);
        titleCell.children().remove(), titleCell.append(titleYearMonth), renderCalback = renderCalback || function () {}, renderCalback();
      }
    }, glDatePicker;
  }();

  !function () {
    Date.prototype._clear = function () {
      return this.setHours(0), this.setMinutes(0), this.setSeconds(0), this.setMilliseconds(0), this;
    }, Date.prototype._time = function () {
      return this._clear().getTime();
    }, Date.prototype._max = function () {
      var e = 1 == new Date(this.getYear(), 1, 29).getMonth() ? 1 : 0,
          t = [31, 28 + e, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      return t[this.getMonth()];
    }, Date.prototype._add = function (e) {
      this.setDate(this.getDate() + e);
    }, Date.prototype._first = function () {
      var e = new Date(this);
      return e.setDate(1), e;
    }, Date.prototype._val = function () {
      return this._clear(), {
        year: this.getFullYear(),
        month: this.getMonth(),
        date: this.getDate(),
        time: this.getTime(),
        day: this.getDay()
      };
    }, Array.prototype._indexOf = function (e) {
      return jQuery.inArray(e, this);
    };
  }();
}(), function (e) {
  e.fn.flexisel = function (t) {
    var a,
        n = e.extend({
      visibleItems: 4,
      animationSpeed: 200,
      autoPlay: !1,
      autoPlaySpeed: 3e3,
      pauseOnHover: !0,
      setMaxWidthAndHeight: !1,
      enableResponsiveBreakpoints: !0,
      clone: !0,
      responsiveBreakpoints: {
        portrait: {
          changePoint: 480,
          visibleItems: 1
        },
        landscape: {
          changePoint: 640,
          visibleItems: 2
        },
        tablet: {
          changePoint: 768,
          visibleItems: 3
        }
      }
    }, t),
        i = e(this),
        r = e.extend(n, t),
        l = !0,
        s = r.visibleItems,
        o = i.children().length,
        c = [],
        d = {
      init: function init() {
        return this.each(function () {
          d.appendHTML(), d.setEventHandlers(), d.initializeItems();
        });
      },
      initializeItems: function initializeItems() {
        var t = i.parent(),
            n = (t.height(), i.children());
        d.sortResponsiveObject(r.responsiveBreakpoints);
        var l = t.width();
        a = l / s, n.width(a), r.clone && (n.last().insertBefore(n.first()), n.last().insertBefore(n.first()), i.css({
          left: -a
        })), i.fadeIn(), e(window).trigger("resize");
      },
      appendHTML: function appendHTML() {
        if (i.addClass("nbs-flexisel-ul"), i.wrap("<div class='nbs-flexisel-container'><div class='nbs-flexisel-inner'></div></div>"), i.find("li").addClass("nbs-flexisel-item"), r.setMaxWidthAndHeight) {
          var t = e(".nbs-flexisel-item img").width(),
              a = e(".nbs-flexisel-item img").height();
          e(".nbs-flexisel-item img").css("max-width", t), e(".nbs-flexisel-item img").css("max-height", a);
        }

        if (e("<div class='nbs-flexisel-nav-left'></div><div class='nbs-flexisel-nav-right'></div>").insertAfter(i), r.clone) {
          var n = i.children().clone();
          i.append(n);
        }
      },
      setEventHandlers: function setEventHandlers() {
        var t = i.parent(),
            n = i.children(),
            o = t.find(e(".nbs-flexisel-nav-left")),
            c = t.find(e(".nbs-flexisel-nav-right"));
        e(window).on("resize", function () {
          d.setResponsiveEvents();
          var l = e(t).width(),
              u = e(t).height();
          a = l / s, n.width(a), r.clone ? i.css({
            left: -a
          }) : i.css({
            left: 0
          });
          var f = o.height() / 2,
              h = u / 2 - f;
          o.css("top", h + "px"), c.css("top", h + "px");
        }), e(o).on("click", function () {
          d.scrollLeft();
        }), e(c).on("click", function () {
          d.scrollRight();
        }), 1 == r.pauseOnHover && e(".nbs-flexisel-item").on({
          mouseenter: function mouseenter() {
            l = !1;
          },
          mouseleave: function mouseleave() {
            l = !0;
          }
        }), 1 == r.autoPlay && setInterval(function () {
          1 == l && d.scrollRight();
        }, r.autoPlaySpeed);
      },
      setResponsiveEvents: function setResponsiveEvents() {
        var t = e("html").width();

        if (r.enableResponsiveBreakpoints) {
          var a = c[c.length - 1].changePoint;

          for (var n in c) {
            if (t >= a) {
              s = r.visibleItems;
              break;
            }

            if (t < c[n].changePoint) {
              s = c[n].visibleItems;
              break;
            }
          }
        }
      },
      sortResponsiveObject: function sortResponsiveObject(e) {
        var t = [];

        for (var a in e) {
          t.push(e[a]);
        }

        t.sort(function (e, t) {
          return e.changePoint - t.changePoint;
        }), c = t;
      },
      scrollLeft: function scrollLeft() {
        if (i.position().left < 0 && 1 == l) {
          l = !1;
          var e = i.parent(),
              t = e.width();
          a = t / s;
          var n = i.children();
          i.animate({
            left: "+=" + a
          }, {
            queue: !1,
            duration: r.animationSpeed,
            easing: "linear",
            complete: function complete() {
              r.clone && n.last().insertBefore(n.first()), d.adjustScroll(), l = !0;
            }
          });
        }
      },
      scrollRight: function scrollRight() {
        var e = i.parent(),
            t = e.width();
        a = t / s;
        var n = a - t,
            c = i.position().left + (o - s) * a - t;
        if (n <= Math.ceil(c) && !r.clone) 1 == l && (l = !1, i.animate({
          left: "-=" + a
        }, {
          queue: !1,
          duration: r.animationSpeed,
          easing: "linear",
          complete: function complete() {
            d.adjustScroll(), l = !0;
          }
        }));else if (r.clone && 1 == l) {
          l = !1;
          var u = i.children();
          i.animate({
            left: "-=" + a
          }, {
            queue: !1,
            duration: r.animationSpeed,
            easing: "linear",
            complete: function complete() {
              u.first().insertAfter(u.last()), d.adjustScroll(), l = !0;
            }
          });
        }
      },
      adjustScroll: function adjustScroll() {
        var e = i.parent(),
            t = i.children(),
            n = e.width();
        a = n / s, t.width(a), r.clone && i.css({
          left: -a
        });
      }
    };
    return d[t] ? d[t].apply(this, Array.prototype.slice.call(arguments, 1)) : "object" != _typeof(t) && t ? (e.error('Method "' + method + '" does not exist in flexisel plugin!'), void 0) : d.init.apply(this);
  };
}(jQuery);